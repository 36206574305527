<template>
  <v-card
    v-if="!loadingPage && !isEmpty(viewConfig)"
    class="px-6 py-1 grey lighten-4"
  >
    <v-card-title class="justify-center">
      <img
        :src="logo"
        alt="Logo"
        class="logo"
      >
      <h2
        v-if="!passwordHasBeenSet"
        class="mt-5"
      >
        {{ viewConfig.set_password.title }}
      </h2>
      <div
        v-else
        class="text-center mt-5"
      >
        <h2>
          {{ viewConfig.set_password.success_title }}
        </h2>
        <p class="mt-2 mb-0">
          {{ viewConfig.set_password.success_text }}
        </p>
        <v-icon
          v-if="passwordHasBeenSet"
          class="mt-2"
          x-large
          color="green darken-1"
        >
          {{ viewConfig.set_password.icon }}
        </v-icon>
      </div>
    </v-card-title>

    <v-card-text>
      <v-form
        v-if="!passwordHasBeenSet"
        @submit.prevent="setPassword"
      >
        <v-card-text>
          <div
            v-for="(attribute, index, key) in viewConfig.edit_form"
            :key="`set-password-${key + 1}`"
          >
            <v-text-field
              v-model="formData[index]"
              solo
              persistent-hint
              :hint="attribute.hint"
              :type="attribute.data_type"
              :label="attribute.label"
              :error-messages="validationErrors[index]"
              @input="clearInputValidationErrors(index)"
            >
              <template #prepend-inner>
                <v-icon>mdi-lock</v-icon>
              </template>
            </v-text-field>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            type="submit"
            :color="viewConfig.set_password.actions.button_save.color"
            v-text="viewConfig.set_password.actions.button_save.text"
          />
        </v-card-actions>
      </v-form>
      <v-container class="mt-6 pa-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="6"
          >
            <span class="footer-text font-weight-medium">{{ name }}</span>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="text-sm-right"
          >
            <span class="footer-text font-weight-medium">{{ phoneNumber }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { api } from '@/global/services/api'
import { has, isEmpty } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('base/config')

export default {
  name: 'ProfileView',

  props: {
    module: {
      type: String,
      default: 'base'
    }
  },

  data () {
    return {
      passwordHasBeenSet: false,
      formData: {},
      validationErrors: {},
      viewConfig: {},
      loadingPage: false
    }
  },

  computed: {
    ...mapGetters({
      logo: 'logo',
      name: 'companyName',
      phoneNumber: 'companyPhoneNumber'
    }),

    formDefaults () {
      return {
        password: null,
        password_confirmation: null
      }
    }
  },

  async created () {
    this.loadingPage = true
    try {
      const { config } = await api()[this.module]
        .get(`/password/set/config/${this.$route.params.langCode}`)
      this.viewConfig = config
    }
    catch (e) {
      await this.$router.push('/login')
    }
    finally {
      this.loadingPage = false
    }
  },

  methods: {
    async setPassword () {
      this.validationErrors = {}

      try {
        const token = this.$route.params.token
        const langCode = this.$route.params.langCode
        const route = `/password/set/${token}?langCode=${langCode}`
        await api().base.post(route, this.formData)

        this.resetFields()
        this.passwordHasBeenSet = true
        await setTimeout(() => (this.$router.push('/login')), 2500)
      }
      catch (e) {
        this.validationErrors = e.getValidationErrors()
      }
    },

    resetFields () {
      this.formData = Object.assign({}, this.formDefaults)
      this.validationErrors = {}
    },

    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.validationErrors[key] = []
      }
    },

    isEmpty (value) {
      return isEmpty(value)
    }
  }
}
</script>
<style scoped lang="scss">
.logo {
  width: 90%;
}

.footer-text {
  color: var(--v-primary-base);
}
</style>
