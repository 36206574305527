<template>
  <v-card
    class="px-6 py-1 lighten-4 text-center"
    style="height: 100%"
  >
    <v-card-text>
      <v-container
        fill-height
        class="pa-0 mb-6"
      >
        <v-row
          no-gutters
          class="mt-4"
        >
          <v-col
            class="text-right"
            cols="12"
            xl="6"
            lg="6"
            md="8"
            sm="10"
            xs="12"
            offset-lx="3"
            offset-lg="3"
            offset-md="2"
            offset-sm="1"
            offset-xs="0"
          >
            <v-form
              ref="form"
              :disabled="savingCompany"
              @submit.prevent="updateCompany"
            >
              <v-card-text
                v-if="viewConfig.edit_form"
                :class="{ 'white rounded-lg elevation-2': updateMode }"
              >
                <v-text-field
                  v-if="!isEmpty(viewConfig.parent_company)"
                  :value="viewConfig.parent_company.name"
                  :disabled="updateMode"
                  :label="viewConfig.parent_company.label"
                  :readonly="true"
                />
                <div
                  v-for="(attribute, index, key) in columns"
                  :key="'create-attribute-' + index"
                >
                  <v-text-field
                    v-if="attribute.type === 'free'"
                    v-model="formData[index]"
                    :label="attribute.label"
                    :readonly="!updateMode"
                    :error-messages="validationErrors[index]"
                    @input="clearInputValidationErrors('first_name')"
                  />
                  <v-select
                    v-if="attribute.type === 'selection'"
                    v-model="formData[index]"
                    :label="attribute.label"
                    :items="formatItemSelectionList(attribute.selectionList)"
                    :error-messages="validationErrors[index]"
                    @input="clearInputValidationErrors(index)"
                  />
                  <autocomplete
                    v-if="attribute.type === 'autocomplete'"
                    :key="'autocomplete-' + formData[index + '_id'] + '-' + key"
                    v-model="formData[index + '_id']"
                    :readonly="!updateMode"
                    :label="attribute.label"
                    :error-messages="validationErrors[index]"
                    :clearable="attribute.clearable"
                    :items.sync="relations[index]"
                    :options="columns[index].autocomplete_options"
                    @input="clearInputValidationErrors(index + '_id')"
                  />
                  <v-checkbox
                    v-if="attribute.type === 'checkbox'"
                    v-model="formData[index]"
                    :label="attribute.label"
                    :readonly="!updateMode"
                    :hide-details="!updateMode"
                    :error-messages="validationErrors[index]"
                    @input="clearInputValidationErrors(index)"
                  />
                  <div v-if="attribute.type === 'file'">
                    <v-file-input
                      v-if="updateMode"
                      v-model="formData[index]"
                      :accept="attribute.data_type === 'image' ? 'image/*' : '*'"
                      :label="attribute.label"
                      :error-messages="validationErrors[index]"
                      @change="handleFileChange($event)"
                    />
                    <v-img
                      :src="imagePreview ? imagePreview : attribute.default"
                      contain
                      :class="{ 'mt-4': !updateMode }"
                      width="auto"
                      max-width="100%"
                    />
                  </div>
                </div>
                <geolocation-map
                  v-if="company.geolocation && company.geolocation.length !== 0"
                  :refresh="refresh"
                  :loadMap="loadMap"
                />
              </v-card-text>

              <div
                v-if="viewConfig.edit_form && userCan('edit')"
                class="text-right mt-4"
              >
                <choose-geolocation-dialog
                  :company="company"
                  :show-dialog="showGeolocationDialog"
                  @refresh-map="refreshMap"
                  @close-geolocation-dialog="showGeolocationDialog = false"
                />

                <choose-partners-dialog v-if="actions.edit_partners" />

                <v-btn
                  color="blue-grey darken-1"
                  class="mr-3"
                  dark
                  @click="showGeolocationDialog = true"
                >
                  {{ company.geolocation && company.geolocation.length !== 0 ? $t('base/company.edit_geolocation') : $t('base/company.choose_geolocation') }}
                </v-btn>

                <v-btn
                  v-show="!updateMode"
                  right
                  dark
                  :color="actions.edit.button_edit.color"
                  :disabled="savingCompany"
                  @click="setUpdateMode"
                  v-text="actions.edit.button_edit.text"
                />

                <v-btn
                  v-show="updateMode"
                  right
                  dark
                  class="mr-4"
                  :color="actions.edit.button_cancel.color"
                  @click="setPreviewMode"
                  v-text="actions.edit.button_cancel.text"
                />

                <v-btn
                  v-show="updateMode"
                  right
                  :disabled="savingCompany"
                  type="submit"
                  :color="actions.edit.button_save.color"
                  v-text="actions.edit.button_save.text"
                />
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import { forEach } from 'lodash/collection'
import { has, reduce, isEmpty } from 'lodash'
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
import ChoosePartnersDialog from '@/modules/base-module/company/components/ChoosePartnersDialog'
import ChooseGeolocationDialog from '@/modules/base-module/company/components/ChooseGeolocationDialog'
import GeolocationMap from '@/modules/base-module/company/components/GeolocationMap'

const { mapGetters, mapActions } = createNamespacedHelpers('base/company')

export default {
  name: 'CompanyView',

  components: {
    GeolocationMap,
    Autocomplete,
    ChoosePartnersDialog,
    ChooseGeolocationDialog
  },

  props: {
    module: {
      type: String,
      default: 'base'
    },
    loadMap: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      updateMode: false,
      savingCompany: false,
      route: '',
      actions: {},
      columns: {},
      formData: {},
      validationErrors: {},
      relations: {},
      imagePreview: null,
      refresh: false,
      showGeolocationDialog: false
    }
  },

  computed: {
    ...mapGetters([
      'company',
      'viewConfig'
    ]),

    formDefaults () {
      return {
        name: null,
        address: null,
        postal_code: null,
        language_id: null,
        city: null,
        country: null,
        identification_number: null
      }
    },

    setColumns () {
      const data = {}

      if ('columns' in this.viewConfig.edit_form) {
        forEach(this.columns, function (column, key) {
          if ('autocomplete_options' in column) {
            data[key] = { options: column.autocomplete_options }
          }
        })
      }

      return data
    }
  },

  watch: {
    viewConfig: {
      deep: true,
      handler: async function (viewConfigValue) {
        const { columns, actions, route } = viewConfigValue.edit_form
        this.columns = columns
        this.actions = actions
        this.route = route
      }
    }
  },

  mounted () {
    this.formData = Object.assign({}, this.formDefaults)

    this.fetch().then(() => {
      this.setFormData()
    })
  },

  methods: {
    ...mapActions(['fetch']),

    userCan (action) {
      if ('actions' in this.viewConfig.edit_form) {
        return action in this.actions
      }

      return false
    },

    refreshMap () {
      this.refresh = false
      const that = this
      setTimeout(() => {
        that.refresh = true
      }, 100)
    },

    formatItemSelectionList (selectionList) {
      const formattedSelection = []
      forEach(selectionList, (text, value) => {
        formattedSelection.push({
          text: text,
          value: value
        })
      })

      return formattedSelection
    },

    async updateCompany () {
      this.validationErrors = {}
      this.savingCompany = true

      const data = new FormData()
      data.append('_method', 'PATCH')
      for (const key in this.formData) {
        data.append(key, this.formData[key])
      }

      try {
        await api()[this.module].post(this.route, data)
          .then(() => (this.fetch().then(() => (this.updateMode = false))))
          .finally(() => (this.savingCompany = false))
      }
      catch (e) {
        this.validationErrors = e.getValidationErrors()
      }
    },

    setUpdateMode () {
      this.updateMode = true
    },

    setPreviewMode () {
      this.updateMode = false
      this.validationErrors = {}
      this.setFormData()
    },

    setFormData () {
      const freshItem = this.company

      this.formData = reduce(this.formData, (result, value, key) => {
        result[key] = (has(freshItem, key) && !!freshItem[key] ? freshItem[key] : null)
        return result
      }, {})

      forEach(this.columns, (item, key) => {
        if ('type' in item && item.type === 'autocomplete') {
          this.formData[key + '_id'] = null
          if (freshItem[key]) {
            this.relations[key + '_id'] = [{
              value: freshItem[key].id,
              text: freshItem[key].name
            }]
          }

          this.formData[key + '_id'] = freshItem[key] && freshItem[key].id ? freshItem[key].id : null
        }
      })
    },

    handleFileChange (data) {
      if (data) {
        this.imagePreview = URL.createObjectURL(data)
      }
      else {
        this.imagePreview = null
      }
    },

    clearServerSideValidationErrors () {
      forEach(this.errors, (value, key) => (this.errors[key] = ''))
    },

    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.validationErrors[key] = []
      }
    },

    isEmpty (value) {
      return isEmpty(value)
    }
  }
}
</script>
