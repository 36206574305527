<template>
  <v-card
    class="px-6 py-1 grey lighten-4"
  >
    <v-card-title class="justify-center">
      <img
        :src="logo"
        alt="Logo"
        class="logo"
      >
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        :disabled="isSubmitting"
        @submit.prevent="submit"
      >
        <v-text-field
          v-model="email"
          :placeholder="$t('base.email')"
          :rules="rules"
          :error-messages="errors.email"
          autofocus
          solo
        >
          <template #prepend-inner>
            <v-icon>mdi-account</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          v-model="new_password"
          :placeholder="$t('base.new_password')"
          :hint="$t('base.min_password_length')"
          persistent-hint
          :rules="rules"
          :error-messages="errors.new_password"
          type="password"
          solo
        >
          <template #prepend-inner>
            <v-icon>mdi-lock</v-icon>
          </template>
        </v-text-field>
        <v-text-field
          v-model="new_password_confirmation"
          :placeholder="$t('base.password_confirmation')"
          :rules="rules"
          :error-messages="errors.new_password_confirmation"
          type="password"
          solo
        >
          <template #prepend-inner>
            <v-icon>mdi-lock</v-icon>
          </template>
        </v-text-field>

        <v-btn
          :disabled="isSubmitting"
          type="submit"
          width="100%"
          color="primary"
        >
          {{ $t('base.resetpassword') }}
        </v-btn>
      </v-form>

      <v-container class="mt-6 pa-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="6"
          >
            <span class="footer-text font-weight-medium">{{ name }}</span>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="text-sm-right"
          >
            <span class="footer-text font-weight-medium">{{ phoneNumber }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import { forEach } from 'lodash/collection'

const { mapGetters } = createNamespacedHelpers('base/config')

export default {
  name: 'LoginView',

  data: () => ({
    isSubmitting: false,
    email: '',
    new_password: '',
    new_password_confirmation: '',
    errors: {
      email: [],
      new_password: [],
      new_password_confirmation: []
    }
  }),

  computed: {
    ...mapGetters({
      logo: 'logo',
      name: 'companyName',
      phoneNumber: 'companyPhoneNumber'
    }),

    rules () {
      return [value => !!value || this.$t('validation/no_attributes.required')]
    }
  },

  methods: {
    async submit () {
      this.clearServerSideValidationErrors()

      if (!this.formValidClientSide()) return

      this.isSubmitting = true

      try {
        const params = {
          email: this.email,
          new_password: this.new_password,
          new_password_confirmation: this.new_password_confirmation,
          token: this.$route.params.pathMatch
        }

        await api().base.post('/password/reset/' + params.token, params)

        // noinspection ES6MissingAwait
        this.$router.push({ path: '/' }).catch(() => {})
      }
      catch (e) {
        if (e.isValidationError) {
          forEach(e.getValidationErrors(), (errors, key) => (this.errors[key] = errors))
        }
      }
      finally {
        this.isSubmitting = false
      }
    },

    formValidClientSide () {
      return this.$refs.form.validate()
    },

    clearServerSideValidationErrors () {
      forEach(this.errors, (value, key) => (this.errors[key] = ''))
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  width: 90%;
}

.footer-text {
  color: var(--v-primary-base);
}
</style>
