<template>
  <div class="d-inline mr-4" style="z-index: 9999;">
    <v-dialog
      v-model="dialog"
      max-width="800px"
      @keydown.enter.prevent="saveGeolocation"
      @keydown.esc.prevent="closeDialog"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <span
            v-if="!existingCompany.geolocation || (existingCompany.geolocation && !existingCompany.geolocation.length)"
            class="headline"
          >
            {{ $t(('base/company.pin_geolocation')) }}
          </span>
          <span
            v-else
            class="headline"
          >
            {{ $t(('base/company.edit_geolocation')) }}
          </span>
        </v-card-title>
        <v-card-text style="margin: 0; padding: 0; border-top: 2px solid lightslategray; border-bottom: 2px solid lightslategray;">
          <v-container style="margin: 0; padding: 0;">
            <v-row style="margin: 0; padding: 0;">
              <v-col cols="12" style="height: 600px; margin: 0; padding: 0;">
                <map-base
                  ref="geolocationMapBase"
                  :map-options="{ doubleClickZoom: false }"
                  :map-class="'company-geolocation-map'"
                  @handleClickEvent="handleMapClick"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="grey lighten-2">
          <v-spacer />
          <v-btn
            color="blue-grey darken-1"
            dark
            @click="closeDialog"
          >
            {{ $t('base.close') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="saveGeolocation"
          >
            {{ $t('base.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import MapBase from '@/global/components/map/MapBase.vue'

const { mapActions } = createNamespacedHelpers('base/company')

export default {
  name: 'ChooseGeolocationDialog',

  components: {
    MapBase
  },

  props: {
    company: {
      type: Object,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      dialog: false,
      chosenGeolocation: null
    }
  },

  emits: ['refresh-map', 'close-geolocation-dialog'],

  computed: {
    existingCompany () {
      return this.company
    }
  },

  watch: {
    showDialog (shouldShowDialog) {
      this.dialog = shouldShowDialog
      if (shouldShowDialog) {
        this.$nextTick(() => {
          this.addExistingLocationOnMap(this.existingCompany)
        })
      }
    }
  },

  methods: {
    ...mapActions(['setCompanyGeolocation']),

    async saveGeolocation () {
      try {
        const { existingCompany, chosenGeolocation } = this
        const { id } = existingCompany || {}

        if (chosenGeolocation !== existingCompany?.geolocation && chosenGeolocation?.lat && chosenGeolocation?.lng && id) {
          await api().base.patch(`/companies/${id}/set-geolocation`, { geolocation: [chosenGeolocation] })
          await this.setCompanyGeolocation(chosenGeolocation)
          this.closeDialog()
          this.$emit('refresh-map')
        }
        else {
          this.closeDialog()
        }
      }
      catch (error) {
        console.log('Error occurred while trying to set company geolocation in profile settings.', error)
      }
    },

    handleMapClick (event) {
      this.chosenGeolocation = event.latlng
      const markers = [
        {
          lat: event.latlng.lat,
          lon: event.latlng.lng,
          draggable: true,
          drag: (event) => this.handleMarkerDragEnd(event)
        }
      ]
      const markerConfig = {
        markers: markers,
        fitBoundsOptions: {
          maxZoom: 16
        },
        fitMarkers: false
      }
      this.generateMarkers(markerConfig)
    },

    handleMarkerDragEnd (event) {
      this.chosenGeolocation = event.target._latlng
    },

    addExistingLocationOnMap (company) {
      if (company && company.geolocation.length && company.geolocation[0] && company.geolocation[0].lat && company.geolocation[0].lng) {
        this.chosenGeolocation = company.geolocation[0]
        const markers = [
          {
            lat: company.geolocation[0].lat,
            lon: company.geolocation[0].lng,
            draggable: true,
            drag: (event) => this.handleMarkerDragEnd(event)
          }
        ]
        const markerConfig = {
          markers: markers,
          fitBoundsOptions: {
            maxZoom: 16
          }
        }
        this.generateMarkers(markerConfig)
      }
    },

    generateMarkers (markersConfig) {
      this.$refs?.geolocationMapBase?.onMapReady(function (map) {
        map.generateMarkers(markersConfig)
      })
    },

    closeDialog () {
      this.$emit('close-geolocation-dialog')
    }
  }
}
</script>

<style scoped>
.v-lazy-first {
  .v-lazy {
    margin: 0 !important;
  }
  .v-lazy #geolocation-div {
    margin: 0 !important;
  }
}
</style>
