<template>
  <v-card
    class="px-6 py-1 green my-2 elevation-3"
  >
    <div class="text-center mt-3">
      <router-link
        :to="{ name: 'Home' }"
        class="text-center"
      >
        <img
          :src="logoSmall"
          alt="Logo"
          class="logo elevation-5 rounded px-5 py-3 green lighten-2"
          width="33%"
        >
      </router-link>
    </div>
    <v-card-title
      class="display-1 justify-center mt-5 mb-2 font-weight-black white--text text--darken-1"
    >
      {{ translations[languageCode].title }}
    </v-card-title>
    <v-tabs
      v-model="selectedTab"
      class="px-5 mb-10"
      vertical
    >
      <v-tab @click="setLanguage('en')">
        <v-icon left>
          mdi-translate
        </v-icon>
        EN
      </v-tab>
      <v-tab @click="setLanguage('hr')">
        <v-icon left>
          mdi-translate
        </v-icon>
        HR
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text
            class="body-1 green--text text--darken-2 text-justify"
            v-html="translations.en.text"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text
            class="body-1 green--text text--darken-2 text-justify"
            v-html="translations.hr.text"
          />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { getEnglishLocaleCodes } from '@/global/services/helpers/dates'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('base/config')

export default {
  name: 'CookiesView',

  props: {
    module: {
      type: String,
      default: 'base'
    }
  },

  data () {
    return {
      selectedTab: null,
      languageCode: 'en',
      expandText: false,
      translations: {
        en: {
          title: 'What are cookies?',
          text: `A cookie is a way of storing information about website users.<br />
            Without the presence of cookies the website has no information about you, so they will not know who you are the next time you visit the site.
            <br /><br />
            This has its pros and cons. If it doesn't have any information about you, a website can’t be of much help to you. It cannot allow you to sign up because there is no information about who you are. It cannot allow you to buy anything because it does not store data about your activities.
            <br /><br />
            But it also means that it cannot track you and use your data in any way without permission. Some websites use cookies to remember what you are doing on their website and in relation to that it places relevant ads for you. Some of these websites even share their cookies so that the ads on one website know what you liked on the other website.
            <br /><br />
            Cookies in themselves are not good or bad, but you need to know what their impact is.<br />
            It is possible to turn them off completely, which is not the best solution because it will affect the user experience when it comes to many websites.
            <br /><br />
            A better option would be to turn off third-party cookies, which will only prevent most websites from sharing information about you.
            <br /><br />
            Most websites have rules that explain what they do if you want to read them.
            <br /><br />
            More than 90% of websites use cookies. Cookies as such are not good or bad, but one needs to understand what can be done with them.`
        },
        hr: {
          title: 'Što su kolačići?',
          text: `Kolačić je način čuvanja informacija o korisnicima web stranice.<br />
          Bez prisustva kolačića web stranica nema nikakve informacije o vama, tako da neće znati ko ste vi kada sljedeći put posjetite stranicu.
          <br /><br />
          Ovo ima svoje dobre i loše strane. Ukoliko nema nikakve informacije o vama, web stranica vam ne može biti mnogo od pomoći. Ne može vam dopustiti da se prijavite, jer nema informaciju o tome ko ste vi. Ne može vam dopustiti da bilo što kupite, jer ne čuva podatke o vašim aktivnostima.
          <br /><br />
          Ali to također znači da vas ne može pratiti i na bilo koji način zloupotrijebiti podatke o vama. Neke web stranice koriste kolačiće da bi se sjetile što radite na njihovoj web stranici i u odnosu na te podatake vam plasira odgovarajuće oglase. Neke od tih web stranica čak i dijele svoje kolačiće, tako da oglasi na jednoj web stranici znaju što vam se svidjelo na drugoj.
          <br /><br />
          Kolačići sami po sebi nisu dobri ili loši, ali treba znati kakav je njihov uticaj.
          <br /><br />
          Moguće ih je potpuno isključiti, što i nije najbolje rješenje jer će se to odraziti na korisničko iskustvo kada su mnoge web stranice u pitanju.
          <br /><br />
          Bolja opcija bila bi isključiti kolačiće treće strane, što će spriječiti većinu web stranica da dijele podatke o vama.
          <br /><br />
          Više od 90% web stranica koristi kolačiće. Kolačići nisu dobri ili loši, ali vrijedi razumjeti što je moguće učiniti s njima.`
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'logoSmall',
      'language'
    ])
  },

  async mounted () {
    if (this.isAuthenticated) {
      this.languageCode = this.language
      this.selectedTab = this.getTabIndex(this.languageCode)
    }
    else {
      const browserLanguageCode = navigator.language.toLowerCase()
      this.languageCode = await this.getCountryCodeByIpAddress() || (getEnglishLocaleCodes().includes(browserLanguageCode) ? 'en' : 'hr')
      this.selectedTab = this.getTabIndex(this.languageCode)
    }
  },

  methods: {
    setLanguage (languageCode) {
      this.languageCode = languageCode
      this.selectedTab = this.getTabIndex(languageCode)
    },

    getTabIndex (languageCode) {
      const languages = {
        en: 0,
        hr: 1
      }

      return languages[languageCode]
    },

    async getCountryCodeByIpAddress () {
      try {
        const r = await fetch('http://ip-api.com/json')
        const response = await r.json()

        return response.countryCode === 'HR' ? 'hr' : 'en'
      }
      catch (error) {
        return false
      }
    }
  }
}
</script>
