<template>
  <div style="height: 400px; margin-top: 10px; border: 3px solid lightgray">
      <map-base
        ref="mapBase"
        :invalidate-map-size="invalidateMapSize"
        :map-options="{ doubleClickZoom: false }"
      />
  </div>
</template>

<script>
import MapBase from '@/global/components/map/MapBase.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('base/company')

export default {
  name: 'GeolocationMap',

  components: {
    MapBase
  },

  props: {
    refresh: {
      type: Boolean,
      default: false
    },
    loadMap: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      invalidateMapSize: false
    }
  },

  watch: {
    loadMap (shouldLoadMap) {
      if (shouldLoadMap) {
        this.$nextTick(() => {
          this.invalidateMapSize = true
          this.addExistingLocationOnMap(this.company)
        })
      }
    },
    refresh: {
      immediate: true,
      handler (shouldRefresh) {
        if (shouldRefresh) {
          this.addExistingLocationOnMap(this.company)
        }
      }
    }
  },

  async created () {
    await this.fetch()
  },

  computed: {
    ...mapGetters(['company'])
  },

  methods: {
    ...mapActions(['fetch']),

    addExistingLocationOnMap (company) {
      if (company && company.geolocation.length && company.geolocation[0] && company.geolocation[0].lat && company.geolocation[0].lng) {
        const markers = [
          {
            lat: company.geolocation[0].lat,
            lon: company.geolocation[0].lng
          }
        ]
        const markerConfig = {
          markers: markers,
          fitBoundsOptions: {
            maxZoom: 16
          }
        }
        this.$refs?.mapBase?.onMapReady(function (map) {
          map.generateMarkers(markerConfig)
        })
      }
    }
  }
}
</script>
