<template>
  <v-card
    class="grey lighten-4 profile-card"
  >
    <v-card-text>
      <v-container
        fill-height
        style="margin: 0; max-width: 100%; padding: 0"
      >
        <v-row
          no-gutters
        >
          <v-col
            class="text-center"
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
          >
            <v-card style="padding: 25px">
              <form @submit.prevent="saveConfirmed">
                <v-tabs
                  v-model="currentTab"
                  vertical
                >
                  <v-tab
                    v-for="(profileTab, profileTabKey) in viewConfig.tabs"
                    :key="`profile-tab-item-${profileTabKey}`"
                    class="justify-start tab-rounded"
                    @click="handleTabClick(profileTabKey)"
                  >
                    {{ profileTab.title }}
                  </v-tab>

                  <v-tab-item
                    v-for="(profileTab, profileTabKey) in viewConfig.tabs"
                    :key="`profile-tab-item-${profileTabKey}`"
                    eager
                  >
                    <template v-if="profileTabKey === 'company'">
                      <CompanyView
                        :load-map="loadMap"
                      />
                    </template>
                    <template v-else-if="profileTabKey === 'sessions'">
                      <SessionsView />
                    </template>
                    <template v-else>
                      <div v-if="profileTabKey === 'basic_info'">
                        <v-card
                          v-if="!updateAvatarMode"
                          class="image-card px-10 py-6"
                        >
                          <v-img
                            :src="imagePreview ? imagePreview : publicImage(avatarUrl)"
                            contain
                            width="auto"
                            max-width="13rem"
                          />
                          <v-btn
                            color="white"
                            icon
                            @click="avatarDialog = true"
                          >
                            <v-icon color="blue-grey">
                              mdi-pencil-box-outline
                            </v-icon>
                          </v-btn>
                        </v-card>
                        <v-dialog
                          v-model="avatarDialog"
                          persistent
                          max-width="800px"
                          @keydown.enter.prevent="saveAvatar"
                          @keydown.esc.prevent="closeAvatarDialog"
                        >
                          <v-card
                            v-if="!updateAvatarMode"
                            class="form-fields-card"
                          >
                            <v-row class="flex justify-space-between ma-0 light-grey">
                              <v-card-title>
                                {{ viewConfig.avatar.title }}
                              </v-card-title>
                              <v-card-text>
                                <v-file-input
                                  v-model="formData.avatar"
                                  :accept="'image/*'"
                                  :label="viewConfig.avatar.label"
                                  :error-messages="validationErrors.avatar"
                                  @change="handleFileChange($event)"
                                />
                              </v-card-text>
                              <v-card-actions style="width: 100%;">
                                <v-spacer />
                                <v-btn
                                  color="blue-grey darken-1"
                                  dark
                                  @click="closeAvatarDialog"
                                >
                                  {{ $t('base.close') }}
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  @click="saveAvatar"
                                >
                                  {{ $t('base.save') }}
                                </v-btn>
                              </v-card-actions>
                            </v-row>
                          </v-card>
                        </v-dialog>
                      </div>
                      <div v-if="!isEmpty(viewConfig)">
                        <v-form
                          ref="form"
                          :disabled="savingProfile"
                        >
                          <v-card-text
                            v-if="showBasicInfoCard(profileTabKey)"
                            class="px-10 py-6"
                            :class="{ 'white rounded-lg elevation-2': showResource(profileTabKey) }"
                          >
                            <div
                              v-for="(attribute, index, key) in viewConfig.tabs[profileTabKey].edit_form.columns"
                              :key="'create-attribute-' + index"
                            >
                              <v-text-field
                                v-if="attribute.type === 'free'"
                                v-model="formData[index]"
                                :label="attribute.label"
                                :readonly="!showResource(profileTabKey)"
                                :error-messages="validationErrors[index]"
                                @input="clearInputValidationErrors('first_name')"
                              />
                              <v-select
                                v-if="attribute.type === 'selection'"
                                v-model="formData[index]"
                                :label="attribute.label"
                                :items="formatItemSelectionList(attribute.selectionList)"
                                :error-messages="validationErrors[index]"
                                @input="clearInputValidationErrors(index)"
                              />
                              <autocomplete
                                v-if="attribute.type === 'autocomplete'"
                                :key="`autocomplete-${formData[index + '_id']}-${key}`"
                                v-model="formData[index + '_id']"
                                :label="attribute.label"
                                :error-messages="validationErrors[index]"
                                :clearable="attribute.clearable"
                                :items.sync="relations[index]"
                                :options="viewConfig.tabs[profileTabKey].columns[index].autocomplete_options"
                                @input="clearInputValidationErrors(index + '_id')"
                              />
                              <dynamic-input-select
                                v-if="attribute.type === 'dynamic_input_select'"
                                :key="`dynamic-input-select-${index}-key`"
                                :label="attribute.label"
                                :show-label-on-mobile="true"
                                :error-messages="getDynamicInputSelectValidationErrors(index)"
                                :clearable="attribute.clearable"
                                :readonly="!showResource(profileTabKey)"
                                :items.sync="relations[index]"
                                :existing-dis-data="formData[dis.key]"
                                :options="attribute.autocomplete_options"
                                :dis="dis"
                                @input="clearInputValidationErrors(index + '_id')"
                                @update-dis-data="disData => setDisData(disData)"
                                @reset-error-message="errorKey => clearInputValidationErrors(errorKey)"
                              />

                              <div
                                v-if="attribute.type === 'modal' && index in dialogs"
                                class="modal-attribute"
                                :class="getAligmnentClass(attribute.button.alignment)"
                              >
                                <div
                                  v-for="(field, ind) in attribute.fields"
                                  :key="`${index}-${ind}`"
                                  class="modal-container"
                                  @click.prevent="openDialog(showResource(profileTabKey), index)"
                                >
                                  <div
                                    v-if="'action_type' in field && field.action_type === 'button'"
                                    class="button-container"
                                    :class="getAligmnentClass(field.button.alignment)"
                                  >
                                    <v-btn
                                      color="blue-grey"
                                      class="mt-2"
                                      right
                                      dark
                                    >
                                      {{ field.title }}
                                    </v-btn>
                                  </div>
                                  <div v-else>
                                    <v-text-field
                                      v-show="!showResource(profileTabKey)"
                                      :value="getModalTextValue(attribute, ind, formData[ind])"
                                      :label="attribute.button.text ? attribute.button.text : ''"
                                      class="modal-text"
                                      readonly
                                      dense
                                    >
                                      <template #label>
                                        {{ attribute.button.text ? attribute.button.text : '' }}
                                        <v-tooltip
                                          v-if="attribute.append_icon && attribute.icon_hover_text"
                                          top
                                        >
                                          <template #activator="{ on, attrs }">
                                            <v-icon
                                              v-if="attribute.append_icon"
                                              small
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              {{ attribute.append_icon }}
                                            </v-icon>
                                          </template>
                                          <span>{{ attribute.icon_hover_text }}</span>
                                        </v-tooltip>
                                        <v-icon
                                          v-if="attribute.append_icon && !attribute.icon_hover_text"
                                          style="vertical-align: middle"
                                          small
                                        >
                                          {{ attribute.append_icon }}
                                        </v-icon>
                                      </template>
                                    </v-text-field>
                                    <v-text-field
                                      v-show="showResource(profileTabKey)"
                                      :value="getModalTextValue(attribute, ind, formData[ind])"
                                      class="modal-text"
                                      readonly
                                      dense
                                    >
                                      <template #label>
                                        {{ attribute.button.text ? attribute.button.text : '' }}
                                        <v-tooltip
                                          v-if="attribute.append_icon && attribute.icon_hover_text"
                                          top
                                        >
                                          <template #activator="{ on, attrs }">
                                            <v-icon
                                              v-if="attribute.append_icon"
                                              small
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              {{ attribute.append_icon }}
                                            </v-icon>
                                          </template>
                                          <span>{{ attribute.icon_hover_text }}</span>
                                        </v-tooltip>
                                        <v-icon
                                          v-if="attribute.append_icon && !attribute.icon_hover_text"
                                          style="vertical-align: middle"
                                          small
                                        >
                                          {{ attribute.append_icon }}
                                        </v-icon>
                                      </template>
                                    </v-text-field>
                                  </div>
                                  <index-modal
                                    :dialog="dialogs[index]"
                                    :dialog-title="attribute.button.text ? attribute.button.text : ''"
                                    :cancel-text="$t('base.cancel')"
                                    :action-text="$t('base.save')"
                                    @action-confirmed="confirmDialogAction(attribute, index, ind)"
                                    @action-canceled="cancelAction(attribute, index, ind)"
                                    @close-dialog="closeDialog(index)"
                                  >
                                    <autocomplete
                                      v-if="field.type === 'autocomplete'"
                                      :key="`modal-autocomplete-${formData[ind + '_id']}-${index}`"
                                      v-model="formData[ind + '_id']"
                                      :label="field.label"
                                      :error-messages="validationErrors[ind + '_id']"
                                      :clearable="field.clearable"
                                      :items.sync="relations[ind + '_id']"
                                      :options="field.autocomplete_options"
                                      @input="clearInputValidationErrors(ind + '_id')"
                                      @update:items="event => setItemInRelations(field, ind, event)"
                                    />
                                    <v-select
                                      v-if="field.type === 'selection'"
                                      :value="formData[index]"
                                      :label="field.label"
                                      :items="formatItemSelectionList(field.selectionList)"
                                      :error-messages="validationErrors[index]"
                                      :clearable="field.clearable || false"
                                      dense
                                      outlined
                                      @input="event => setValueAndClearInputValidationErrors(event, index)"
                                    />
                                    <div v-if="field.type === 'treeview' && field.items.length > 0 && ind in formData">
                                      <v-treeview
                                        v-model="formData[ind]"
                                        :error-messages="validationErrors[ind]"
                                        :class="[update_settings_mode ? '' : 'homepage-treeview']"
                                        selectable
                                        selected-color="blue"
                                        :items="treeViews[ind].items"
                                        :open.sync="treeViews[ind].open"
                                        @input="clearInputValidationErrors(ind)"
                                      />
                                      <p
                                        v-show="getValidationErrorMessage(ind)"
                                        class="red--text ma-0"
                                        v-text="getValidationErrorMessage(ind)"
                                      />
                                    </div>
                                    <div>
                                      <change-password
                                        v-if="field.type === 'change_password'"
                                        :can-edit="canEdit"
                                        :field-config="field"
                                        :validation-errors="validationErrors"
                                        @set-validation-error-key="event => setValidationErrorKey(event)"
                                        @set-password-form-data="event => setPasswordFormData(ind, event)"
                                      />
                                    </div>
                                  </index-modal>
                                </div>
                              </div>
                            </div>
                          </v-card-text>

                          <v-card-actions
                            v-if="checkForTabActions(profileTabKey)"
                            class="justify-end my-2 mx-3"
                          >
                            <v-btn
                              v-if="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_edit"
                              v-show="!showResource(profileTabKey)"
                              right
                              dark
                              :color="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_edit.color"
                              :disabled="showResource(profileTabKey)"
                              @click="setUpdateMode(profileTabKey)"
                              v-text="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_edit.text"
                            />

                            <v-btn
                              v-if="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_cancel"
                              v-show="showResource(profileTabKey)"
                              right
                              dark
                              class="mr-4"
                              :color="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_cancel.color"
                              @click="setPreviewMode(profileTabKey)"
                              v-text="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_cancel.text"
                            />

                            <v-btn
                              v-if="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_save"
                              v-show="showResource(profileTabKey)"
                              right
                              :disabled="savingProfile"
                              type="submit"
                              :color="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_save.color"
                              @click.prevent="updateResource(profileTabKey, viewConfig.tabs[profileTabKey])"
                              v-text="viewConfig.tabs[profileTabKey].edit_form.actions.edit.button_save.text"
                            />
                          </v-card-actions>
                        </v-form>
                      </div>
                    </template>
                  </v-tab-item>
                </v-tabs>
                <v-divider />
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import {
  has,
  isArray,
  isEmpty,
  forEach
} from 'lodash'
import withoutWatchersMixin from '@/global/mixins/withoutWatchersMixin'
import { defaultAvatarUrl } from '@/global/common//imagePaths'
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
import ChangePassword from '@/modules/base-module/profile/views/ChangePassword'
import DynamicInputSelect from '@/modules/fleet-module/dynamic-input-select/DynamicInputSelect'
import IndexModal from '@/global/components/modals/IndexModal'
import CompanyView from '@/modules/base-module/company/views/CompanyView'
import SessionsView from '@/modules/base-module/sessions/views/SessionsView'

const { mapGetters, mapActions } = createNamespacedHelpers('base/profile')
const {
  mapActions: mapActionsConfig
} = createNamespacedHelpers('base/config')

export default {
  name: 'ProfileView',

  components: {
    SessionsView,
    CompanyView,
    Autocomplete,
    ChangePassword,
    DynamicInputSelect,
    IndexModal
  },

  mixins: [withoutWatchersMixin],

  props: {
    module: {
      type: String,
      default: 'base'
    }
  },

  data () {
    return {
      vTab: 0,
      savingProfile: false,
      update_basic_info_mode: false,
      update_alarm_contacts_mode: false,
      update_settings_mode: false,
      avatarDialog: false,
      avatarUrl: null,
      openId: [],
      formData: {},
      home_page: [],
      dis: {},
      validationErrors: {},
      passwordValidationErrors: {},
      relations: {},
      treeViews: {},
      treeItems: [],
      dialogs: {},
      updateAvatarMode: true,
      imagePreview: null,
      currentTab: null,
      loadMap: false
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'viewConfig'
    ]),

    canEdit () {
      return this.viewConfig.can_edit ?? false
    },

    formDefaults () {
      return {
        username: null,
        first_name: null,
        last_name: null,
        email: null,
        password: null
      }
    },

    setColumns () {
      const data = {}

      if ('columns' in this.viewConfig.tabs.basic_info.edit_form) {
        forEach(this.viewConfig.tabs.basic_info.edit_form.columns, function (column, key) {
          if ('autocomplete_options' in column) {
            data[key] = { options: column.autocomplete_options }
          }
        })
      }

      return data
    }
  },

  watch: {
    formData: {
      deep: true,
      handler (formData) {
        Object.keys(this.treeViews).forEach(treViewKey => {
          if (formData[treViewKey].length > 1) {
            this.$withoutWatchers(() => {
              this.formData[treViewKey].shift()
            })
          }
        })
      }
    },

    viewConfig: {
      deep: true,
      handler (viewConfig) {
        const toManyDataTypes = new Set(['many2many_relation', 'one2many_relation'])
        for (const [tabKey, tab] of Object.entries(viewConfig.tabs)) {
          if (tabKey === 'company' || tabKey === 'sessions') {
            continue
          }
          if (tab.free_edit) {
            this.setUpdateMode(tabKey)
          }

          if (!isEmpty(tab.edit_form.columns)) {
            for (const [key, {
              data_type: dataType,
              fields
            }] of Object.entries(tab.edit_form.columns)) {
              if (toManyDataTypes.has(dataType)) {
                this.dis.key = key
                this.dis.fields = fields
                this.dis.formDataFields = this.getFormDataFields(fields)
              }
            }
          }
        }

        this.initializeFields(viewConfig)
      }
    },

    user: {
      deep: true,
      handler (user) {
        this.updateAvatarMode = false
        this.avatarUrl = user.avatar_url
      }
    }
  },

  created () {
    this.formData = Object.assign({}, this.formDefaults)

    this.fetch().then(() => (this.setFormData()))
  },

  methods: {
    ...mapActions(['fetch']),
    ...mapActionsConfig(['setUser']),

    isEmpty,

    handleTabClick (tabKey) {
      this.loadMap = tabKey === 'company'
    },

    handleFileChange (data) {
      this.validationErrors.avatar = []
      if (data) {
        this.imagePreview = URL.createObjectURL(data)
      }
      else {
        this.imagePreview = null
      }
    },

    setItemInRelations (field, key, event) {
      const relations = { ...this.relations }
      relations[field.field] = event
      this.relations = Object.assign({}, relations)
    },

    async saveAvatar () {
      const formData = {
        avatar: this.formData.avatar
      }

      let errorHappened = false
      try {
        const { data: avatarUrl } = await api().base.patch('avatar', formData)
        this.avatarUrl = avatarUrl
        this.$parent.$emit('avatar-updated')
      }
      catch (e) {
        errorHappened = true
        if (e.response?.status === 422) {
          this.validationErrors = e.getValidationErrors()
        }
      }
      finally {
        if (!errorHappened) {
          this.closeAvatarDialog()
        }
      }
    },

    getModalTextValue (attribute, key, formDataValue) {
      let textValue
      if (attribute.fields[key].type === 'treeview') {
        textValue = this.getTreeviewSelectedValue(attribute, key, formDataValue)
      }
      else if (attribute.fields[key].type === 'selection') {
        const adaptedKey = attribute.fields[key].selected ? 1 : 0
        return attribute.fields[key].selectionList[adaptedKey]
      }
      else if (attribute.fields[key].type === 'autocomplete') {
        textValue = attribute.fields[key].selected.name
      }

      return textValue
    },

    getTreeviewSelectedValue (attribute, key, selected) {
      if (selected && selected.length > 0) {
        return attribute.fields[key].item_translations_map[selected[0]]
      }

      return null
    },

    checkForTabActions (profileTabKey) {
      const tabs = this.viewConfig.tabs

      return !isEmpty(tabs) &&
        tabs[profileTabKey].edit_form.actions &&
        'edit' in tabs[profileTabKey].edit_form.actions &&
        this.canEdit
    },

    cancelAction (attribute, index, key) {
      this.closeDialog(key)
      this.formData[key] = [attribute.fields[key].selected_item]
      this.initializeFields(this.viewConfig)
    },

    async confirmDialogAction (attribute, key, index) {
      const { method, route } = attribute
      const formDataKey = attribute.fields[index]?.field ?? index

      let formDataObject = {}
      let formData = {}
      if (formDataKey === 'change_password') {
        if (formDataKey in this.formData) {
          formDataObject = this.formData[formDataKey]
        }

        formData = {
          ...formDataObject
        }
      }
      else {
        formDataObject = this.formData[formDataKey]
        formData = {
          [formDataKey]: formDataObject
        }
      }

      let validationErrorsHappened = false
      await api()[this.module][method](route, formData)
        .then(({ data: user }) => {
          this.setUser(user)
          this.fetch()
        })
        .catch(e => {
          if (e.response.status === 422) {
            validationErrorsHappened = true
            this.validationErrors = e.getValidationErrors()
          }
        })
        .finally(() => {
          if (!validationErrorsHappened) {
            this.closeDialog(index)
          }
        })
    },

    setValidationErrorKey ({ key, event }) {
      this.validationErrors[key] = event
    },

    setPasswordFormData (column, event) {
      if (!(column in this.formData)) {
        this.formData[column] = {}
      }
      for (const [key, value] of Object.entries(event)) {
        this.formData[column][key] = value
      }
    },

    getItems (tabKey) {
      return this.viewConfig.tabs[tabKey].edit_form.items ?? []
    },

    showBasicInfoCard (key) {
      return !!this.viewConfig.tabs[key].edit_form
    },

    showResource (tabKey) {
      return this[`update_${tabKey}_mode`]
    },

    getValidationErrorMessage (key) {
      return this.validationErrors[key] ? this.validationErrors[key][0] : ''
    },

    extractSelectedItemFromTreeItems (fieldKey, selectedItem) {
      // const treeView = this.treeViews[fieldKey]
      const queue = this.treeViews[fieldKey].items.map(item => {
        return { node: item, parentKey: null }
      })

      while (queue.length) {
        const currentNode = queue[0]

        if (currentNode.node.id === selectedItem) {
          if ('children' in currentNode.node) {
            this.treeViews[fieldKey].open.push(currentNode.node.id)
            break
          }
          else {
            const parentNode = this.treeViews[fieldKey].items.find(item => item.id === currentNode.parentKey)
            if (parentNode) {
              this.treeViews[fieldKey].open.push(parentNode.id)
              break
            }
          }
        }

        if (currentNode.node.children?.length) {
          const children = currentNode
            .node
            .children
            .map(item => ({
              node: item,
              parentKey: currentNode.node.id
            }))
          queue.push(...children)
        }
        queue.shift()
      }
    },

    formatItemSelectionList (selectionList) {
      const formattedSelection = []
      forEach(selectionList, (text, value) => {
        formattedSelection.push({
          text: text,
          value: value
        })
      })

      return formattedSelection
    },

    async updateResource (tabKey, { method, route }) {
      let formData = {}
      let formFields = []
      if (['basic_info', 'alarm_contacts'].includes(tabKey)) {
        formFields = Object.keys(this.viewConfig.tabs[tabKey].edit_form.columns)
        formFields.forEach(field => (formData[field] = this.formData[field]))
      }
      else if (tabKey === 'home_page') {
        formData = { home_page: this.home_page }
      }
      this.validationErrors = {}

      let errorHappened = false
      await api()[this.module][method](route, formData)
        .then(({ data: user }) => {
          this.setUser(user)
          this.fetch()
        })
        .catch(e => {
          errorHappened = true
          if (e.response.status === 422) {
            this.validationErrors = e.getValidationErrors()
          }
        })
        .finally(() => {
          if (!errorHappened) {
            this[`update_${tabKey}_mode`] = false
          }
        })
    },

    setUpdateMode (tabKey) {
      this[`update_${tabKey}_mode`] = true
    },

    setPreviewMode (tabKey) {
      this[`update_${tabKey}_mode`] = false
      this.validationErrors = {}
      this.setFormData()
    },

    setFormData () {
      const freshItem = this.user

      forEach(this.formData, (result, key, value) => {
        if (has(freshItem, key)) {
          this.formData[key] = freshItem[key] ? freshItem[key] : null
        }
      })

      forEach(this.viewConfig.tabs, (tab, tabKey) => {
        if (tabKey === 'company' || tabKey === 'sessions') {
          return
        }
        forEach(tab.edit_form.columns, (item, key) => {
          if ('type' in item && item.type === 'dynamic_input_select' && freshItem[key]) {
            const isOneToMany = item.data_type === 'one2many_relation'
            this.formData[key] = this.modifyDisData(freshItem[key], isOneToMany)
          }
        })
      })
    },

    setDisData (disData) {
      this.$set(this.formData, disData.dis_key, [...disData.data])
    },

    getDynamicInputSelectValidationErrors (index) {
      return Object.keys(this.validationErrors)
        .filter(key => key.startsWith(index))
        .reduce((obj, key) => {
          obj[key] = this.validationErrors[key]
          return obj
        }, {})
    },

    getFormDataFields (fields) {
      const obj = {}
      for (const [key, value] of Object.entries(fields)) {
        if (value.form_data) {
          obj[key] = value
        }
      }

      return obj
    },

    modifyDisData (disData, isOneToMany) {
      return isArray(disData) && disData.length ? this.disFieldsFormatted(disData, isOneToMany) : []
    },

    disFieldsFormatted (disData, formData = false) {
      return disData.map(disDataObject => this.disFieldFormatted(disDataObject, formData))
    },

    disFieldFormatted (disDataObject, formData = false) {
      const fields = formData ? this.dis.formDataFields : this.dis.fields
      const obj = {}
      for (const [key, value] of Object.entries(fields)) {
        switch (value.type) {
          case 'primary_key':
            obj[key] = parseInt(disDataObject[key])
            break
          case 'foreign_key':
            obj[key] = formData ? parseInt(disDataObject[key]) : parseInt(disDataObject.id)
            break
          case 'string':
            obj[key] = disDataObject[key]
            break
        }
      }
      return obj
    },

    clearServerSideValidationErrors () {
      forEach(this.errors, (value, key) => (this.errors[key] = ''))
    },

    publicImage (imageUrl) {
      return imageUrl || defaultAvatarUrl
    },

    initializeFields (viewConfig) {
      if ('tabs' in viewConfig) {
        let allColumns = {}
        for (const tabKey in viewConfig.tabs) {
          if (tabKey === 'company' || tabKey === 'sessions') {
            continue
          }
          const tabValue = viewConfig.tabs[tabKey]
          allColumns = { ...allColumns, ...tabValue.edit_form.columns }
        }

        for (const columnKey in allColumns) {
          const column = allColumns[columnKey]
          if (column.type === 'modal') {
            for (const fieldKey in column.fields) {
              const field = column.fields[fieldKey]
              if (field.type === 'treeview') {
                this.treeViews[fieldKey] = {
                  items: field.items,
                  open: []
                }

                this.extractSelectedItemFromTreeItems(fieldKey, field.selected_item)
                this.$set(this.formData, fieldKey, [field.selected_item])
              }
              else if (field.type === 'selection') {
                const selectionValue = field.selected ? 1 : 0
                this.$set(this.formData, fieldKey, selectionValue)
              }
              else if (field.type === 'autocomplete') {
                const autocompleteRelation = this.relations[fieldKey + '_id'] ?? []
                if (autocompleteRelation.length === 0) {
                  this.$withoutWatchers(() => {
                    this.relations[fieldKey + '_id'] = [{
                      value: field.selected.id,
                      text: field.selected.name
                    }]
                  })
                }

                this.formData[fieldKey + '_id'] = field.selected.id ?? null

                // Check if field is from nested relationship
                if ('field' in column && column.field.includes('.')) {
                  this.formData[fieldKey + '_id'] = this.getNestedRelationField(column.field, field.selected)
                }
              }
            }
            this.dialogs[columnKey] = column.dialog
          }
        }
      }
    },

    openDialog (showResource, index) {
      if (showResource) {
        this.$set(this.dialogs, index, true)
        const dialogs = { ...this.dialogs }
        this.dialogs = Object.assign({}, dialogs)
      }
    },

    closeDialog (index) {
      this.dialogs[index] = false
      if (index === 'change_password') {
        const changePasswordColumns = Object.keys(
          this.viewConfig.tabs.settings.edit_form.columns.change_password.fields.change_password.columns
        )
        changePasswordColumns.forEach(column => (this.validationErrors[column] = []))
      }
      this.initializeFields(this.viewConfig)
    },

    closeAvatarDialog () {
      this.avatarDialog = false
      this.validationErrors.avatar = []
      this.imagePreview = null
      this.formData.avatar = null
    },

    getAligmnentClass (alignment) {
      if (alignment === 'left') {
        return 'justify-start'
      }
      else if (alignment === 'center') {
        return 'justify-center'
      }
      else {
        return 'justify-end'
      }
    },

    setValueAndClearInputValidationErrors (event, key) {
      this.formData[key] = event
      this.clearInputValidationErrors(key)
    },

    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.validationErrors[key] = []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.profile-card {
  height: 100%;
}

.image-card {
  margin: 0;
  background: none;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  width: fit-content;
}

.modal-attribute {
  display: flex;
  margin: 0.5rem 0;
  .modal-container {
    width: 100%;
    .button-container {
      display: flex;
    }
  }
  .modal-text {
    cursor: pointer;
    margin-top: 1rem;
  }
}

::v-deep {
  .v-treeview-node__toggle + .v-treeview-node__checkbox {
    display: none !important;
  }
  .v-treeview-node__content {
    text-align: left !important;
  }
}

::v-deep .homepage-treeview .v-treeview-node__checkbox {
  opacity: 0.5;
  pointer-events: none;
}

.tab-rounded {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>
