<template>
  <div class="d-inline mr-4">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="30em"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="blue-grey darken-1"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('base/company.choose_partners') }}
        </v-btn>
      </template>
      <v-card>
        <v-row class="flex justify-space-between align-center ma-0">
          <v-card-title>{{ $t('base/company.choose_partners_title') }}</v-card-title>

          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="dialog = false"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <v-card-text>
          <v-checkbox
            v-for="company in companies"
            :key="company.id"
            v-model="selectedCompanies"
            hide-details
            :value="company.id"
            :label="company.name"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey darken-1"
            dark
            @click="dialog = false"
          >
            {{ $t('base.close') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="savePartners"
          >
            {{ $t('base.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { api } from '@/global/services/api'

export default {
  name: 'ChoosePartnersDialog',

  data: () => ({
    dialog: false,
    partners: [],
    companies: [],
    selectedCompanies: [],
    validationErrors: {}
  }),

  created () {
    this.getPartners()
  },

  methods: {
    async getPartners () {
      const { data } = await api().base.get('/company/partners')
      this.companies = data.companies
      this.selectedCompanies = data.partners.map(item => item.id)
    },
    async savePartners () {
      this.validationErrors = {}
      try {
        await api().base.patch('/company/partners', { partners: this.selectedCompanies })
      }
      catch (e) {
        this.validationErrors = e.getValidationErrors()
      }
      this.dialog = false
    }
  }
}
</script>
