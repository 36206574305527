<template>
  <div>
    <v-card
      class="px-6 py-1 grey lighten-4"
    >
      <v-card-title class="justify-center">
        <img
          :src="logo"
          alt="Logo"
          class="logo"
        >
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          :disabled="isSubmitting || loginBlocked"
          @submit.prevent="submitLogin"
        >
          <v-text-field
            v-model="login"
            :placeholder="$t('base.usernameemail')"
            :rules="rules"
            autofocus
            solo
          >
            <template #prepend-inner>
              <v-icon>mdi-account</v-icon>
            </template>
          </v-text-field>

          <v-text-field
            v-model="password"
            :placeholder="$t('base.password')"
            :rules="rules"
            type="password"
            solo
          >
            <template #prepend-inner>
              <v-icon>mdi-lock</v-icon>
            </template>
          </v-text-field>

          <v-btn
            :disabled="isSubmitting || loginBlocked"
            type="submit"
            width="100%"
            color="primary"
          >
            {{ $t('base.login') }}
          </v-btn>
        </v-form>

        <v-container class="pa-0 my-6">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
            >
              <v-btn to="/forgot-password">
                {{ $t('base.forgotpassword') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="mt-6 pa-0">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
            >
              <span class="footer-text font-weight-medium">{{ name }}</span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="text-sm-right"
            >
              <span class="footer-text font-weight-medium">{{ phoneNumber }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <vue-cookie-accept-decline
      :ref="'ecoMobileCookiePanel'"
      :element-id="'ecoMobileCookiePanel'"
      :debug="false"
      :position="'bottom'"
      :type="'bar'"
      :disable-decline="false"
      :transition-name="'slideFromBottom'"
      :show-postpone-button="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <!-- Optional -->
      <div slot="postponeContent">
        &times;
      </div>

      <!-- Optional -->
      <div slot="message">
        {{ $t('base.cookie_text') }}
        <router-link :to="{ name: 'Cookies' }">
          {{ $t('base.here') }}
        </router-link>
      </div>

      <!-- Optional -->
      <div slot="declineContent">
        {{ $t('base.decline') }}
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        {{ $t('base.accept') }}
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import { forEach } from 'lodash/collection'

import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

const { mapGetters } = createNamespacedHelpers('base/config')

export default {
  name: 'LoginView',

  components: {
    VueCookieAcceptDecline
  },

  data: () => ({
    status: null,
    isSubmitting: false,
    login: '',
    password: '',
    timezone: '',
    errors: {
      login: [],
      password: []
    },
    loginBlocked: false
  }),

  computed: {
    ...mapGetters({
      logo: 'logo',
      name: 'companyName',
      phoneNumber: 'companyPhoneNumber'
    }),

    rules () {
      return [value => !!value || this.$t('validation/no_attributes.required')]
    }
  },

  mounted () {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    this.timezone = dayjs.tz.guess()
  },

  methods: {
    cookieStatus (status) {
      this.status = status
    },

    cookieClickedAccept () {
      this.status = 'accept'
    },

    cookieClickedDecline () {
      this.status = 'decline'
    },
    removeCookie () {
      this.$refs.ecoMobileCookiePanel.removeCookie()
      this.$refs.ecoMobileCookiePanel.init()
    },

    async submitLogin () {
      if (this.status === null || this.status === 'decline') {
        alert(this.$t('base.cookies_declined_warning'))
        this.removeCookie()
      }
      else {
        this.clearServerSideValidationErrors()

        if (!this.formValidClientSide()) return

        this.isSubmitting = true

        try {
          const params = {
            login: this.login,
            password: this.password,
            timezone: this.timezone
          }

          const response = await api().base.post('/login', params)
          const path = response.data.home_page ?? '/'

          await this.$router.push({ path })
        }
        catch (e) {
          console.log('Error occurred in login view submit logic', e)
        }
        finally {
          this.isSubmitting = false
        }
      }
    },

    formValidClientSide () {
      return this.$refs.form.validate()
    },

    clearServerSideValidationErrors () {
      forEach(this.errors, (value, key) => (this.errors[key] = ''))
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  width: 90%;
}

.footer-text {
  color: var(--v-primary-base);
}

.notification-bar {
  position: static;
  height: auto;
}
</style>
