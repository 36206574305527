<template>
  <div class="mb-3 change-password-wrapper">
      <div
        v-for="(attribute, index, key) in fieldConfig.columns"
        :key="`change-password-${key + 1}`"
      >
        <v-text-field
          v-if="canEdit"
          v-model="passwordFormData[index]"
          :type="attribute.data_type"
          :label="attribute.label"
          :error-messages="validationErrors[index]"
          @input="clearInputValidationErrors(index)"
        />
      </div>
  </div>
</template>

<script>
import { has } from 'lodash'

export default {
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },

    fieldConfig: {
      type: Object,
      default: () => ({})
    },

    validationErrors: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      changePasswordDialog: false,
      passwordFormData: {},
      defaultDialogSize: {
        xl: '33.33%',
        lg: '33.33%',
        md: '50%',
        sm: '66.66%',
        xs: '100%'
      }
    }
  },

  watch: {
    passwordFormData: {
      deep: true,
      handler (passwordFormData) {
        this.$emit('set-password-form-data', passwordFormData)
      }
    }
  },

  methods: {
    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.$emit('set-validation-error-key', { key, value: [] })
      }
    },

    resetValidationErrors () {
      const validationErrors = { ...this.validationErrors }
      Object.keys(this.fieldConfig.columns).forEach(column => {
        if (column in validationErrors) {
          this.$emit('set-validation-error-key', { key: column, value: [] })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.change-password-wrapper {
  width: 100%;
}
</style>
